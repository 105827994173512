import React from "react"
import { graphql } from "gatsby"
import { Container, Row, Col } from "react-grid-system"
import styled from "styled-components"
import Layout from "../components/compassi/layout"
import SEO from "../components/editions/2020/seo"
import {
  Display,
  Heading,
  HeadingSmall,
} from "../components/editions/2020/typography"
import ContactForm from "../components/editions/2020/contactForm"
import InnerNav from "../components/editions/2020/innerNav"
import DesignTokens from "../components/editions/2020/designTokens"
import Sponsors from "../components/compassi/sponsors"
import Footer from "../components/home/blocks/footer"
import { getCustomPage } from "../utils/path"
import { Box } from "theme-ui"

const ReminderPage = ({ data: { site, page } }) => {
  let tmpi18nPaths = []
  site.locales.map(locale => {
    tmpi18nPaths.push({
      locale: locale,
      value: getCustomPage(page, locale),
    })
  })
  const i18nPaths = tmpi18nPaths

  return (
    <Layout locale={page.locale} i18nPaths={i18nPaths} theme="dark">
      <SEO title={page.seo.title} />
      <InnerNav />
      <CustomContainer>
        <Row justify="center">
          <Col md={12} lg={9} xl={6}>
            <Display>{page.title}</Display>
            <HeadingSmall>{page.subtitle}</HeadingSmall>
          </Col>
        </Row>
        <Row justify="center">
          <Col md={12} lg={9} xl={6}>
            <Box>
              <ContactForm />
            </Box>
          </Col>
        </Row>
      </CustomContainer>
      {page.content &&
        page.content.map(block => (
          <Box
            as="section"
            key={block.id}
            id={block.id}
            sx={{
              position: "relative",
              zIndex: 99,
              px: [0, 0],
            }}
          >
            {block.model.apiKey === "footer2023" && <Box sx={{ px: [3, 4, 7],py:[4]}}><Footer block={block} /></Box>}
            {block.model.apiKey === "sponsors_compassi" && (
              <Sponsors block={block} />
            )}
          </Box>
        ))}
    </Layout>
  )
}

const CustomContainer = styled(Container)`
  h1 {gatsby cle
    margin-bottom: 1rem;
  }
  margin-top: 3rem;
  margin-bottom: 3rem;
  & > * {
    margin-bottom: 3rem;
    &:last-child {
      margin-bottom: none;
    }
  }
`

export default ReminderPage

export const query = graphql`
  query ReminderPageQuery {
    site: datoCmsSite {
      locales
    }
    page: datoCmsReminderPage {
      id
      title
      subtitle
      content {
        ... on DatoCmsFooter2023 {
          id
          privacy {
            ... on DatoCmsExternalLink {
              ...ExternalLinkDetails
            }
            ... on DatoCmsInternalLink {
              ...InternalLinkDetails
            }
          }
          cookie {
            ... on DatoCmsExternalLink {
              ...ExternalLinkDetails
            }
            ... on DatoCmsInternalLink {
              ...InternalLinkDetails
            }
          }
          credits {
            ... on DatoCmsExternalLink {
              ...ExternalLinkDetails
            }
            ... on DatoCmsInternalLink {
              ...InternalLinkDetails
            }
          }
          legalInfo
          model {
            apiKey
          }
        }
        ... on DatoCmsSponsorsCompassi {
          id
          content {
            ... on DatoCmsLogoBlock {
              section
              partners {
                id
                name
                text
                whiteLogo {
                  url
                  alt
                }
                url
              }
            }
          }
          model {
            apiKey
          }
        }
      }
      seo {
        title
        description
      }
    }
  }
`
