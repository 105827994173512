import React, { useState } from "react"
import { navigate } from "gatsby"
import axios from "axios"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Container, Row, Col } from "react-grid-system"

const ContactForm = () => {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [loading, setLoading] = useState(false)

  const handleSubmit = event => {
    event.preventDefault()
    setLoading(true)
    const options = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
    const data = {
      name: name,
      email: email,
      subscribedAt: new Date().toISOString(),
    }
    axios
      .post(
        "https://hooks.zapier.com/hooks/catch/7165528/oscv8nl",
        data,
        options
      )
      .then(function (response) {
        
        if (typeof window !== "undefined" && window.dataLayer !== undefined) {
          window.dataLayer = window.dataLayer || []

          window.dataLayer.push({
            event: "form_submit",
            formType: "Contact",
          })
        }

        navigate("/iscrizione", {
          state: { reminder: true },
        })
      })
      .catch(function (error) {
        console.log(error)
        setLoading(false)
      })
  }

  return (
    <Form method="post" name="contact" onSubmit={handleSubmit}>
      <Row>
        <Col md={6}>
          <section>
            <label htmlFor="name">Nome e cognome *</label>
            <input
              onChange={e => setName(e.target.value)}
              type="text"
              name="name"
              id="name"
              value={name}
              required
            />
          </section>
        </Col>
        <Col md={6}>
          <section>
            <label htmlFor="email">Email *</label>
            <input
              onChange={e => setEmail(e.target.value)}
              type="text"
              name="email"
              id="email"
              value={email}
              required
            />
          </section>
        </Col>
      </Row>
      <Row>
        <Col>
          <section>
            <p>
              Dopo aver preso visione della presente informativa sulla privacy
              ai sensi dell'ART.13 D.Lgs. n.196 del 2003, a) Acconsento al
              trattamento dei dati personali al fine esclusivo di avere
              informazioni riguardo i servizi relativi al presente sito web. Per
              completare la registrazione è necessaria l'autorizzazione al
              trattamento dei dati personali.
            </p>
          </section>
          <Checkbox>
            <input type="checkbox" id="privacy" name="privacy" required />
            <label htmlFor="privacy">Si, acconsento</label>
          </Checkbox>
        </Col>
      </Row>
      <Row>
        <Col>
          <section>
            <input
              type="submit"
              value={loading ? `Caricamento...` : `Iscriviti`}
            />
          </section>
        </Col>
      </Row>
    </Form>
  )
}

export default ContactForm

const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
  div {
    margin: 0 ${DesignTokens.spacing[2]};
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`

const Checkbox = styled.div`
  display: flex;
  flex-direction: row;
  input {
    margin-right: 0.5rem;
  }
  label {
    font-size: 14px;
    line-height: 1.1;
    margin-bottom: 0 !important;
  }
`

const RadioButton = styled.div`
  display: flex;
  flex-direction: row;
  input {
    margin-right: 0.5rem;
  }
  label {
    font-size: 14px;
    line-height: 1.1;
    margin-bottom: 0 !important;
  }
`

const Form = styled.form`
  width: 100%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  label {
    margin-bottom: ${DesignTokens.spacing[4]};
    text-transform: lowercase;
    font-weight: bold;
    color: #515a61;
  }
  input,
  textarea {
    transition: all 0.15s ease;
    padding: ${DesignTokens.spacing[4]};
    border: 1px solid ${DesignTokens.colors.black};
    outline: none;
    border-radius: ${DesignTokens.borderRadius};
    &:hover,
    &:focus {
      border: 1px solid ${DesignTokens.colors.primary[500]};
    }
  }

  input[type="submit"] {
    cursor: pointer;
    background: ${DesignTokens.colors.primary[500]};
    border: none;
    color: ${DesignTokens.colors.white};
    border-radius: ${DesignTokens.borderRadius};
    text-transform: uppercase;
    font-weight: bold;
    &:hover,
    &:focus {
      background: ${DesignTokens.colors.primary[900]};
    }
    &:active {
      background: ${DesignTokens.colors.primary[500]};
    }
  }
  section {
    display: flex;
    flex-direction: column;
    p {
      font-size: 12px;
      color: #555555;
    }
  }
  & > * {
    margin-bottom: ${DesignTokens.spacing[7]};
    &:last-child {
      margin-bottom: 0;
    }
  }
`
